import React from 'react';
import { connect } from 'react-redux';

import { closePopup } from '../actions/popup';
import { updateOrderTaxFromShippingAddress,updateOrder } from '../actions/order';

import { BASE_ZINDEX } from '../popup-factory';

import Select from './Select';

class SelectTaxPopup extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      tax_id: props.taxes.map(t => t.key)[0]
    };

    this.handleChangeTax = this.handleChangeTax.bind(this);
  }

  handleChangeTax(e) {
    e.preventDefault();
    const { onUpdateNoTax, onUpdateTax, onClosePopup } = this.props;
    const { tax_id } = this.state;
    if (tax_id) {
      onUpdateTax(tax_id);
    } else {
      onUpdateNoTax();
    }
    onClosePopup();
  }

  render() {
    const { taxes, onClosePopup, index } = this.props;
    const { tax_id } = this.state;
    return (
      <div id="replace-header-modal" className="reveal tiny" style={{overflow: 'visible', display: 'block', height: 'auto', zIndex: BASE_ZINDEX + index}} aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className="row">
          <div className="small-12 columns">
            <h3 id="modalTitle">Update Tax</h3>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <p>Would you like to update the tax rate based on the new address?</p>
            {taxes.length > 0 && <p>Select the new tax rate:</p>}
            {taxes.length > 0 && <Select options={taxes} value={tax_id} change={tax_id => this.setState({ tax_id })} />}
          </div>
        </div>
        <div className="row column popup-content">
          <div className="expanded button-group">
            <a className="button" onClick={() => onClosePopup()}>No</a>
            <a className="alt button" onClick={this.handleChangeTax}>Yes</a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  taxes: ownProps.possible_taxes.map(t => ({ key: t.tax_id, value: `${t.description} (${t.percent}%)` }))
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClosePopup: () => dispatch(closePopup()),
  onUpdateNoTax: () => dispatch(updateOrderTaxFromShippingAddress(ownProps.order_id)),
  onUpdateTax: tax_id => dispatch(updateOrder(ownProps.order_id, 'tax_id', ownProps.tax_id, tax_id))
});

const ConnectedSelectTaxPopup = connect(mapStateToProps, mapDispatchToProps)(SelectTaxPopup);
export default ConnectedSelectTaxPopup;
